$(document).ready(() => {
  $('#unsolved-tab').click(function () {
    $('#unsolved-tab a:first').removeClass('tab-disactive');
    $('#unsolved-tab a:first').addClass('tab-active');
    $('#replied-tab a:first').removeClass('tab-active');
    $('#replied-tab a:first').addClass('tab-disactive');
    $('#resolved-tab a:first').removeClass('tab-active');
    $('#resolved-tab a:first').addClass('tab-disactive');
  });
  $('#replied-tab').click(function () {
    $('#resolved-tab a:first').removeClass('tab-active');
    $('#resolved-tab a:first').addClass('tab-disactive');
    $('#replied-tab a:first').removeClass('tab-disactive');
    $('#replied-tab a:first').addClass('tab-active');
    $('#unsolved-tab a:first').removeClass('tab-active');
    $('#unsolved-tab a:first').addClass('tab-disactive');
  });
  $('#resolved-tab').click(function () {
    $('#resolved-tab a:first').removeClass('tab-disactive');
    $('#resolved-tab a:first').addClass('tab-active');
    $('#replied-tab a:first').removeClass('tab-active');
    $('#replied-tab a:first').addClass('tab-disactive');
    $('#unsolved-tab a:first').removeClass('tab-active');
    $('#unsolved-tab a:first').addClass('tab-disactive');
  });
});
